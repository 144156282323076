import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { pcLogo, pcLogoWhite } from '../../../public/img/s3Images';

export const Container = styled.div<{
    backgroundColor?: string;
    main?: boolean;
}>`
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.backgroundColor};
    position: fixed;
    top: 0;
    z-index: 3;
    /* ${({ main }) =>
        main &&
        css`
            top: 80px;
        `} */
`;

export const Box = styled.div`
    width: 1080px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1100px) {
        width: 1000px;
    }
`;

export const LoginButton = styled.div`
    font-weight: 500;
    color: #b4b4b4;
    background: rgba(242, 242, 242, 0.1);
    border: 1px solid #f2f2f2;
    border-radius: 15px;
    padding: 8.5px 20px;
    cursor: pointer;
`;

export const TabBox = styled.div`
    display: flex;
    gap: 50px;
    color: ${props => props.color};
`;

export const Tab = styled.span<{
    active?: boolean;
}>`
    display: inline-block;
    font-weight: 500;
    line-height: 19px;
    cursor: pointer;
    &:hover {
        font-weight: 700;
    }
    ${({ active }) =>
        active &&
        css`
            font-weight: 700;
        `}
`;

type PCGnbProps = {
    backgroundColor?: string;
    color?: string;
    isLogin: boolean;
    onClickMyResume: () => void;
    onClickMypage: () => void;
    onClickLogoInEdit: () => void;
    scroll?: boolean;
    basicType?: boolean;
    main?: boolean;
};

function PCGnb({
    backgroundColor,
    color,
    isLogin,
    onClickMyResume,
    onClickMypage,
    onClickLogoInEdit,
    scroll,
    basicType,
    main,
}: PCGnbProps) {
    const [pathName, setPathName] = useState('/');

    useEffect(() => {
        setPathName(window.location.pathname);
    }, []);

    const renderLogo = () => {
        if (basicType || main) {
            return (
                <Image
                    src={pcLogo}
                    width={104}
                    height={24}
                    style={{
                        cursor: 'pointer',
                    }}
                    alt="logo"
                />
            );
        }
        return (
            <Link href="/resumes">
                <Image
                    src={scroll ? pcLogo : pcLogoWhite}
                    width={104}
                    height={24}
                    style={{
                        cursor: 'pointer',
                    }}
                    alt="logo"
                />
            </Link>
        );
    };

    return (
        <Container backgroundColor={backgroundColor} main={main}>
            <Box>
                <div onClick={onClickLogoInEdit}>{renderLogo()}</div>
                {isLogin && (
                    <TabBox color={color}>
                        <div onClick={onClickMyResume}>
                            <Tab
                                active={
                                    !['/resumes', '/mypage'].includes(pathName)
                                }
                            >
                                내 이력서
                            </Tab>
                        </div>
                        <div onClick={onClickMypage}>
                            <Tab active={pathName === '/mypage'}>
                                마이페이지
                            </Tab>
                        </div>
                    </TabBox>
                )}

                {!isLogin && (
                    <Link href="/login">
                        <LoginButton> 로그인 ∙ 회원가입</LoginButton>
                    </Link>
                )}
            </Box>
        </Container>
    );
}

export default PCGnb;
