import styled, { css } from 'styled-components';

const DropdownBackground = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
`;
const DropdownList = styled.ul<{
    dropDownType?: string;
}>`
    background-color: #101016;
    color: #4b4d4f;
    transition: 0.28s;
    padding: 30px 15px 30px 15px;
    display: flex;
    flex-direction: column;
    gap: 70px;

    ${({ dropDownType }) =>
        dropDownType &&
        css`
            background-color: ${dropDownType};
        `}
`;

const DropdownItem = styled.li<{
    active?: boolean;
}>`
    font-size: 16px;
    font-weight: 500;
    ${({ active }) =>
        active &&
        css`
            font-weight: 700;
        `}
    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}
`;

const TextLinkBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export { DropdownBackground, DropdownList, DropdownItem, TextLinkBox };
