import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
    DropdownBackground,
    DropdownItem,
    DropdownList,
    TextLinkBox,
} from 'styles/header/headerStyle';
import blockScrollInModal from 'utils/blockScrollInModal';
import clearBlockScrollInModal from 'utils/clearBlockScrollInModal';
import { mobileLogo, mobileLogoWhite } from '../../../public/img/s3Images';

export const AllContainer = styled.div<{
    main?: boolean;
}>`
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    color: ${props => props.color};
    /* ${({ main }) =>
        main &&
        css`
            top: 81px;
        `} */
`;

export const MobileContainer = styled.div<{
    backgroundColor?: string;
    dropDownType?: string;
}>`
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.backgroundColor};
`;

export const MobileBox = styled.div`
    width: 89.3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Logo = styled.img`
    cursor: pointer;
    display: block;
`;

const LoginButton = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: #b4b4b4;
    border-radius: 15px;
`;

const MenuIcon = styled.span``;

export const CloseIcon = styled.span<{
    main?: boolean;
}>`
    color: #000;
`;

export const LogoLink = styled.a`
    height: 15px;
`;

export const LogoDiv = styled.div`
    height: 15px;
`;

type MainGnbProps = {
    backgroundColor?: string;
    color?: string;
    isLogin: boolean;
    onClickMyResume: () => void;
    onClickMypage: () => void;
    onClickLogoInEdit: () => void;
    dropDownType?: string;
    scroll?: boolean;
    basicType?: boolean;
    main?: boolean;
};

function MobileGnb({
    backgroundColor,
    color,
    isLogin,
    onClickMyResume,
    onClickMypage,
    onClickLogoInEdit,
    dropDownType,
    main,
    basicType,
    scroll,
}: MainGnbProps) {
    const [dropDown, setDropDown] = useState(false);
    const [pathName, setPathName] = useState('/');

    useEffect(() => {
        setPathName(window.location.pathname);
    }, []);

    const ref = useRef<HTMLDivElement>(null);

    const renderLogo = () => {
        if (basicType || main) {
            return (
                <LogoDiv onClick={onClickLogoInEdit}>
                    <img
                        src={mobileLogo}
                        width={65}
                        style={{
                            cursor: 'pointer',
                        }}
                        alt="logo"
                    />
                </LogoDiv>
            );
        }
        return (
            <LogoLink href="/resumes">
                <img
                    src={scroll ? mobileLogo : mobileLogoWhite}
                    width={65}
                    style={{
                        cursor: 'pointer',
                    }}
                    alt="logo"
                />
            </LogoLink>
        );
    };

    useEffect(() => {
        dropDown && blockScrollInModal();
        return () => {
            clearBlockScrollInModal();
        };
    }, [dropDown]);

    console.log(backgroundColor);
    return (
        <AllContainer color={color} ref={ref} main={main}>
            {!dropDown && (
                <MobileContainer backgroundColor={backgroundColor}>
                    <MobileBox>
                        {renderLogo()}
                        {isLogin && (
                            <MenuIcon
                                className="material-symbols-outlined"
                                onClick={() => setDropDown(true)}
                            >
                                menu
                            </MenuIcon>
                        )}
                        {!isLogin && (
                            <Link href="/login">
                                <LoginButton> 로그인 ∙ 회원가입</LoginButton>
                            </Link>
                        )}
                    </MobileBox>
                </MobileContainer>
            )}
            {dropDown && (
                <DropdownBackground>
                    <MobileContainer
                        backgroundColor="#fff"
                        dropDownType={dropDownType}
                    >
                        <MobileBox>
                            <LogoLink href="/">
                                <Logo src={mobileLogo} alt="logo" width={65} />
                            </LogoLink>

                            <CloseIcon
                                className="sc-jrcTuL jbHJAo material-symbols-outlined"
                                onClick={() => setDropDown(false)}
                                main={main}
                            >
                                close
                            </CloseIcon>

                            {!isLogin && (
                                <Link href="/login">
                                    <LoginButton>
                                        {' '}
                                        로그인 ∙ 회원가입
                                    </LoginButton>
                                </Link>
                            )}
                        </MobileBox>
                    </MobileContainer>
                    <DropdownList
                        dropDownType={dropDownType}
                        style={{ paddingBottom: '50px' }}
                    >
                        <TextLinkBox>
                            <div onClick={onClickMyResume}>
                                <DropdownItem
                                    active={
                                        !['/resumes', '/mypage'].includes(
                                            pathName,
                                        )
                                    }
                                >
                                    내 이력서
                                </DropdownItem>
                            </div>
                            <div onClick={onClickMypage}>
                                <DropdownItem active={pathName === '/mypage'}>
                                    마이페이지
                                </DropdownItem>
                            </div>
                        </TextLinkBox>
                    </DropdownList>
                </DropdownBackground>
            )}
        </AllContainer>
    );
}

export default MobileGnb;
